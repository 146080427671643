import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
// import { useStaticQuery, graphql } from 'gatsby';
import { Row, Col, Card, Skeleton } from 'antd';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PageWrapper = styled.div`
  overflow: hidden;
  max-width: 1366px;
  margin: auto;
  padding: 40px 60px;
  padding-top: 120px;

  /* .upcoming-events-col {
    padding: 20px;
  } */
  .title {
    margin-bottom: 24px;
  }
  .event-card {
    margin: 16px;

    .ant-card-body {
      padding: 16px;
    }
    .event-image-col {
      max-height: 150px;
      overflow: hidden;

      @media only screen and (max-width: 768px) {
        margin-bottom: 16px;
        max-height: 300px;
      }

    }
    .card-images {
      margin: 0;
      object-fit: cover;
    }
  }
  .event-title {
    margin-bottom: 8px;
  }
  .event-sched {
    font-size: 14px;
    color: #aaaaaa;
  }
  .event-no-link-cta {
    font-size: 16px;
    line-height: 1.2;
  }
  .outbound-link {
    font-weight: 600;
    text-decoration: underline;
  }
`;

function PrimeRadarSchedulesPage({ location }) {
  const [isLoading, setLoading] = useState(true);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const dateToday = new Date().toISOString();
  useEffect(() => {
    fetch(
      `https://hephaestus-backend.herokuapp.com/upcoming-events?_sort=event_date:DESC`
    )
      .then(response => response.json()) // parse JSON from request
      .then(eventArray => {
        setLoading(false);
        eventArray.forEach(event => {
          // Sorting by ISO date string comparison. Doing new Date() or any other constructor here won't be good performance-wise.
          if (dateToday > event.event_date) {
            setPastEvents(state => [...state, event]);
          } else {
            setUpcomingEvents(state => [event, ...state]);
          }
        });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Layout source="Event Schedules Page">
      <SEO
        title="Event Schedules"
        description="Our webinar series providing a new, enlightening perspective on the modern Philippine real estate industry."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'webinar, prime webinar, prime radar, real estate webinar, Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <PageWrapper>
        <Row gutter={[0, 40]} type="flex" justify="center">
          <Col
            className="upcoming-events-col"
            xs={24}
            sm={24}
            md={14}
            lg={16}
            xl={16}
            xxl={16}
          >
            <h2 className="title">Upcoming Events</h2>
            <Skeleton loading={isLoading} active={true}/>
            {upcomingEvents.map(event => {
              return (
                <Card className="event-card" key={event.strapiId}>
                  <Row gutter={[20, 0]} type="flex" justify="center">
                    <Col
                      className="event-image-col"
                      xs={24}
                      sm={24}
                      md={10}
                      lg={8}
                      xl={8}
                      xxl={8}
                    >
                      <img
                        alt={`${event.title}`}
                        src={`${process.env.IMAGE_ASSETS}/radar-webinars/${event.banner_image_url}`}
                        className="card-images"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={16} xl={16} xxl={16}>
                      <h3 className="event-title">{event.title}</h3>
                      <div className="event-sched">
                        {moment(event.event_date).format(
                          'MMMM DD, YYYY - hh:mm A'
                        )}
                      </div>
                      {event.url_path ? (
                        <OutboundLink
                          href={event.url_path}
                          className="outbound-link"
                        >
                          {event.cta_text}
                        </OutboundLink>
                      ) : (
                        <div className="event-no-link-cta">{event.cta_text}</div>
                      )}
                    </Col>
                  </Row>
                </Card>
              );
            })}
          </Col>
          <Col xs={23} sm={23} md={10} lg={8} xl={8} xxl={8}>
            <h2 className="title">Past Events</h2>
            <Skeleton loading={isLoading} active={true}/>
            {pastEvents.map(event => {
              return (
                <Card className="event-card" key={event.strapiId}>
                  <Row gutter={[20, 0]} type="flex" justify="center">
                    <Col
                      className="event-image-col"
                      xs={24}
                      sm={24}
                      md={10}
                      lg={8}
                      xl={8}
                      xxl={8}
                    >
                      <img
                        alt={`${event.title}`}
                        src={`${process.env.IMAGE_ASSETS}/radar-webinars/${event.banner_image_url}`}
                        className="card-images"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={16} xl={16} xxl={16}>
                      <h5 className="event-title">{event.title}</h5>
                      <div className="event-sched">
                        {moment(event.event_date).format(
                          'MMMM DD, YYYY - hh:mm A'
                        )}
                      </div>
                      {event.url_path ? (
                        <OutboundLink
                          href={event.url_path}
                          className="outbound-link"
                        >
                          {event.cta_text}
                        </OutboundLink>
                      ) : (
                        <div className="event-no-link-cta">{event.cta_text}</div>
                      )}
                    </Col>
                  </Row>
                </Card>
              );
            })}
          </Col>
        </Row>
      </PageWrapper>
    </Layout>
  );
}

export default PrimeRadarSchedulesPage;
